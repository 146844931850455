<template>
  <div>
    <el-row :gutter="20">
      <el-col :xs="24" :sm="12" class="pt-5 homepayment__box">
        <div id="chart">
          <apexchart
            type="bar"
            height="350"
            :key="mode"
            :options="chartOptions"
            v-if="series[0].data.length"
            :series="series"
          ></apexchart>
        </div>
      </el-col>

      <el-col :xs="24" :sm="12" class="pt-5 pr-5 homepayment__box">
        <div>
          <el-card class="box-card bg-light-primary mb-4" :class="mode ? '' : 'text-primary__night'">
            <i class="my-icon el-icon-pie-chart text-primary"></i>
            <div class="homepayment__flex">
              <div class="text-center mobile__right">
                <span  class="text-primary">{{$t("message.assigned_salary")}} </span>
              </div>
              <div class="text-center mt-3">
                <b class="text-primary">{{ Number(salaryReports.salary).toLocaleString()}}</b>
              </div>
            </div>
          </el-card>
        </div>
        <div>
          <el-card class="box-card bg-light-warning mb-4" :class="mode ? '' : 'text-warning__night'">
            <i class="my-icon el-icon-data-line text-warning"></i>
            <div class="homepayment__flex">
              <div class="text-center">
                <span class="text-warning mobile__right">{{$t("message.last_month")}}</span>
              </div>
              <div class="text-center mt-3">
                <b class="text-warning"> {{ Number(salaryReports.last).toLocaleString() }} </b>
              </div>
            </div>
          </el-card>
        </div>
        <div>
          <el-card class="box-card bg-light-success mb-4" :class="mode ? '' : 'text-success__night'">
            <i class="my-icon el-icon-collection-tag text-success"></i>
            <div class="homepayment__flex">
              <div class="text-center">
                <span class="text-success mobile__right"> {{$t("message.current_month")}} </span>
              </div>
              <div class="text-center mt-3">
                <b class="text-success">{{ Number(salaryReports.current).toLocaleString() }}</b>
              </div>
            </div>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  props: {
    staff_id: {
      type: Number,
    },
  },

  data() {
    return {
      series: [
        {
          name: "Заработная",
          data: [],
        },
      ],
      chartOptions: {
        chart: {
          type: "bar",
          height: 350,
          foreColor: ''
        },
        plotOptions: {
          bar: {
            borderRadius: 5,
            horizontal: false,
            columnWidth: "40%",
            endingShape: "rounded",
          },
        },

        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },

        xaxis: {
          categories: [
            "Назначенная заработная плата",
            "Прошлый месяц",
            "Текущий месяц",
          ],
        },
        yaxis: {
          title: {
            text: "$ (thousands)",
          },
        },
        fill: {
          opacity: 1,
        },
        colors: [
          function ({ value, seriesIndex, w }) {
            if (w.config.series[0].data[0]?(w.config.series[0].data[0] ===  value):false) {
              return "#009EF7";
            } 
            else if (w.config.series[0].data[1]?w.config.series[0].data[1] ===  value:false) {
              return "#FFC700";
            } else if (w.config.series[0].data[2]?(w.config.series[0].data[2] ===  value):false) {
              return "#50CD89";
            } else {
              return "#F1416C";
              // o'ta yomon natija
            }
          },
        ],

        tooltip: {
          y: {
            formatter: function (val) {
              return "UZB " + val.toLocaleString() + " сум";
            },
          },
        },
      },
    };
  },
  mounted() {
    this.table();
    this.changeOtion()
  },
  watch: {
    mode () {
      this.changeOtion()
    }
  },
  computed: {
    ...mapGetters({
      salaryReports: "advanceSalary/salaryReports",
      mode: "MODE"
    }),
    actions: function () {
      return ["edit", "delete"];
    },
  },
  methods: {
    ...mapActions({
      updateList: "advanceSalary/salaryReports",
    }),
    table() {
      const query = { staff_id: this.staff_id };
      var self = this;
      this.updateList(query).then((res) => {
        var arrayR = res.data.result.data.salary_reports;
        self.series[0].data.push(+arrayR.salary, +arrayR.last, +arrayR.current);
      });
    },
    changeOtion () {
      if (this.mode) {
        this.chartOptions.chart.foreColor = '#373d3f'
      } else {
        this.chartOptions.chart.foreColor = '#fff'
      }
    }
   
  },
};
</script>
