<template>
  <div>
    <div
      class="card-table mb-5"
      :class="mode ? 'cardtable__day' : 'cardtable__night'"
    >
      <div
        class="workers-info-title-za"
        :class="mode ? 'text__day2' : 'text__night2'"
      >
        <div class="title">{{ $t("message.foreign_languages") }}</div>
        <i
          class="el-icon-plus"
          @click="create = true"
          v-can="'profile.foreignLanguages.create'"
        ></i>
      </div>
      <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
        <table class="w-100 td20" v-loading="loadLanguage">
          <tr>
            <td>
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
                >{{ $t("message.nameGiven") }}</strong
              >
            </td>

            <td>
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.social") }}
              </strong>
            </td>

            <td>
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.degree") }}</strong
              >
            </td>

            <td>
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.datas") }}
              </strong>
            </td>
          </tr>
          <tr
            v-for="(language, index) in staffLanguages"
            :key="'language-' + index"
          >
            <td>
              <p
                :class="mode ? 'text__day2' : 'text__night2'"
                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
              >
                <span>
                  {{ language.language ? language.language.name : "" }}
                </span>
              </p>
            </td>
            <td>
              <p
                :class="mode ? 'text__day2' : 'text__night2'"
                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
              >
                <span>{{ language.place_of_study }} </span>
              </p>
            </td>
            <td>
              <p
                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                <span>{{ language.level }}</span>
              </p>
            </td>

            <td>
              <p
                :class="mode ? 'text__day2' : 'text__night2'"
                class="font-medium mb-3 mt-3"
              >
                <span>
                  {{ language.created_at }}
                </span>
              </p>
            </td>

            <td class="text-right">
              <el-row>
                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editStaffLanguage(language)"
                  v-can="'profile.foreignLanguages.edit'"
                ></el-button>
                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="deleteStaffClientLanguage(language)"
                  v-can="'profile.foreignLanguages.destroy'"
                ></el-button>
              </el-row>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <el-dialog
      class="ichki_modal"
      :title="$t('message.foreign_languages')"
      :visible.sync="create"
      width="60%"
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      center
    >
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.lang") }}
              </span>
              <select-language
                :size="'medium'"
                v-model="form.language_id"
                :id="form.language_id"
                :placeholder="$t('message.lang')"
              ></select-language>
            </div>
          </el-col>
          <!-- end col -->
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.social") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                v-model="form.place_of_study"
                :placeholder="$t('message.social')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
          <el-col :span="24">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.degree") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                v-model="form.level"
                :placeholder="$t('message.degree')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="submit()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="close()">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>

    <el-dialog
      class="ichki_modal"
      :title="$t('message.foreign_languages')"
      :visible.sync="update"
      width="60%"
      center
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
    >
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.lang") }}
              </span>
              <select-language
                :size="'medium'"
                v-model="form.language_id"
                :id="form.language_id"
                :placeholder="$t('message.lang')"
              ></select-language>
            </div>
          </el-col>
          <!-- end col -->
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.social") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                :inputValue="form.place_of_study"
                v-model="form.place_of_study"
                :placeholder="$t('message.social')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
          <el-col :span="24">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.degree") }}
              </span>
              <crm-input
                :class="mode ? 'input__day' : 'input__night'"
                :size="'medium'"
                :inputValue="form.level"
                v-model="form.level"
                :placeholder="$t('message.degree')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="updateLanguage()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="close()">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import selectLanguage from "@/components/filters/inventory/select-language";
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  components: {
    selectLanguage
  },
  data() {
    return {
      form: {},
      loadLanguage: false,
      create: false,
      update: false
    };
  },
  created() {
    this.fetchStaffLanguage();
  },
  computed: {
    ...mapGetters({
      staffLanguages: "profile/staff_languages",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      getStaffLanguage: "profile/getStaffLanguage",
      addStaffLanguage: "profile/addStaffLanguage",
      updateStaffLanguage: "profile/updateStaffLanguage",
      deleteStaffLanguage: "profile/deleteStaffLanguage"
    }),
    submit() {
      this.form.staff_id = this.$route.params.id;
      if (this.form.staff_id) {
        this.addStaffLanguage(this.form)
          .then(res => {
            this.$alert(res);
            if (res.status == 201) {
              this.fetchStaffLanguage();
              this.close();
            }
          })
          .catch(err => {
            this.$alert(err);
          });
      }
    },

    fetchStaffLanguage() {
      if (!this.loadLanguage && this.$route.params.id) {
        this.loadLanguage = true;
        this.getStaffLanguage(this.$route.params.id)
          .then(res => {
            this.loadLanguage = false;
          })
          .catch(err => {
            this.loadLanguage = false;
          });
      }
    },
    close() {
      this.clearForm();
      this.create = false;
      this.update = false;
    },
    clearForm() {
      for (var key in this.form) {
        this.form[key] = null;
      }
    },
    editStaffLanguage(language) {
      this.form = JSON.parse(JSON.stringify(language));
      this.update = true;
    },
    opened(refs) {
      this.$refs[refs].opened();
    },
    open(language) {
      this.form = JSON.parse(JSON.stringify(language));
      this.update = true;
    },
    updateLanguage() {
      this.form.staff_id = this.$route.params.id;
      this.form.language_id = this.form.id;
      if (this.form.staff_id && this.form.language_id) {
        this.loadLanguage = true;
        this.updateStaffLanguage(this.form)
          .then(res => {
            this.$alert(res);
            this.loadLanguage = false;
            if (res.status == 201) {
              this.fetchStaffLanguage();
              this.close();
            }
          })
          .catch(err => {
            this.$alert(err);
            this.loadLanguage = false;
          });
      }
    },
    deleteStaffClientLanguage(language) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning"
      })
        .then(() => {
          if (!this.loadLanguage && this.$route.params.id) {
            this.loadLanguage = true;
            let form = {
              staff_id: this.$route.params.id,
              language_id: language.id
            };
            this.deleteStaffLanguage(form)
              .then(res => {
                this.loadLanguage = false;
                this.$alert(res);
                if (res.status == 200) {
                  this.fetchStaffLanguage();
                }
              })
              .catch(err => {
                this.loadLanguage = false;
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена"
          });
        });
    }
  }
};
</script>
<style>
.dialog__mynight .el-dialog__footer {
  background-color: transparent !important;
}
</style>
