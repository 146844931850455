<template>
  <div>
    <el-form ref="form" :model="form">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{$t('message.educational_institution')}}</span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
              :inputValue="form.name"
              :placeholder="$t('message.educational_institution')"
              v-model="form.name"
              :size="'medium'"
            ></crm-input>
          </div>
        </el-col>
        <!-- end col -->
        <el-col :span="12">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.degree')}} </span>
            <select-education
              v-model="form.level"
            ></select-education>
          </div>
        </el-col>

        <el-col :span="12">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.faculty')}} </span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
              :inputValue="form.faculty"
              :placeholder="$t('message.faculty')"
              v-model="form.faculty"
              :size="'medium'"
            ></crm-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="12">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.beginning_date')}} </span>
            <crm-date-picker
              :placeholder="$t('message.beginning_date')"
              v-model="form.started_date"
              :date="form.started_date"
              size="medium"
              :class="mode ? 'input__day' : 'input__night'"
            ></crm-date-picker>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.expiration_date')}} </span>
            <crm-date-picker
              :placeholder="$t('message.expiration_date')"
              v-model="form.finished_date"
              :date="form.finished_date"
              size="medium"
              :class="mode ? 'input__day' : 'input__night'"
            ></crm-date-picker>
          </div>
        </el-col>
        <!-- end col -->
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer my-style-modal">
      <el-button type="success" size="medium" @click="submit()" >
        {{$t("message.save")}}
      </el-button>
      <el-button type="warning" size="medium" @click="close()" >
         {{$t("message.close")}}
      </el-button>
    </span>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
import SelectEducation from '../../../../components/filters/inventory/select-education.vue';
export default {
  data() {
    return {
      form: {},

    SelectEducation    };
  },
  computed: {},
  methods: {
    ...mapActions({
      addStaffEducation: "profile/addStaffEducation",
      mode: "MODE"

    }),
    submit() {
      this.form.staff_id = this.$route.params.id;
      if (this.form.staff_id) {
        this.addStaffEducation(this.form)
          .then((res) => {
            this.$alert(res);
            if (res.status == 201) {
              this.clearForm();
              this.parent().fetchStaffEducation();
              this.close();
            }
          })
          .catch((err) => {
            this.$alert(err);
          });
      }
    },
    close() {
      this.clearForm();
      this.parent().closeEducationDialog();
    },
    parent() {
      return this.$parent.$parent;
    },
    closed() {
      if (_.isFunction(this.afterLeave)) {
        this.afterLeave();
      }
    },
    opened() {
      if (_.isFunction(this.afterOpen)) {
        this.afterOpen();
      }
    },
    clearForm() {
      for (var key in this.form) {
        this.form[key] = null;
      }
    },
  },
};
</script>
