<template>
  <div>
    <el-form ref="form" :model="form">
      <el-row :gutter="20">
        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.mobil_number')}} </span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
                :type="'tel'"
                :maskFormat="'tel'"
                :placeholder="$t('message.mobil_number')"
                :inputValue="form.personal_phone_number"
                v-model="form.personal_phone_number"
                :size="'medium'"
            ></crm-input>
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.work_phone')}} </span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
                :type="'tel'"
                :maskFormat="'tel'"
                :placeholder="$t('message.work_phone')"
                :inputValue="form.home_phone_number"
                 v-model="form.home_phone_number"
                :size="'medium'"
            ></crm-input>            
          </div>
        </el-col>
        <!-- end col -->       
        <!-- end col -->
        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.facebook_url')}}</span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
              :inputValue="form.facebook"
              :placeholder="$t('message.facebook_url')"
               v-model="form.facebook"
              :size="'medium'"
            ></crm-input>            
          </div>
        </el-col>
        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{ $t('message.instagram_url')}} </span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
              :inputValue="form.instagram"
              :placeholder="$t('message.instagram_url')"
               v-model="form.instagram"
              :size="'medium'"
            ></crm-input>            
          </div>
        </el-col>
        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2"> {{$t('message.twitter_url')}} </span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
              :inputValue="form.twitter"
              :placeholder="$t('message.twitter_url')"
               v-model="form.twitter"
              :size="'medium'"
            ></crm-input>            
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">{{ $t('message.linkedIn_url')}} </span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
              :inputValue="form.linkedin"
              :placeholder="$t('message.linkedIn_url')"
               v-model="form.linkedin"
              :size="'medium'"
            ></crm-input>            
          </div>
        </el-col>
        <!-- end col -->

        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
             {{ $t('message.telegram_username')}}
            </span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
              :inputValue="form.telegram"
              :placeholder="$t('message.telegram_username')"
               v-model="form.telegram"
              :size="'medium'"
            ></crm-input>                      
          </div>
        </el-col>
        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
             {{ $t('message.tiktok_username')}}
            </span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
              :inputValue="form.tiktok"
              :placeholder="$t('message.tiktok_username')"
               v-model="form.tiktok"
              :size="'medium'"
            ></crm-input>                      
          </div>
        </el-col>
        <el-col :span="24">
          <div class="app-form__group mb-4">
            <span :class="mode ? 'text__day2' : 'text__night2'" class="input--label d-block mb-2">
              {{$t('message.email')}}
            </span>
            <crm-input
            :class="mode ? 'input__day' : 'input__night'"
              :inputValue="form.email"
              :placeholder="$t('message.email')"
               v-model="form.email"
              :size="'medium'"
            ></crm-input>                      
          </div>
        </el-col>
        
        <!-- end col -->
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer my-style-modal">
      <el-button type="success" size="medium" @click="storeContact()" >
        {{$t("message.save")}}
      </el-button>
      <el-button
        type="warning"
        size="medium"
        @click="close"
      >
        {{$t("message.close")}}
      </el-button>
    </span>
  </div>
</template>
<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
export default {
  data() {
    return {
      form: {},
    };
  },
  props: {
    staff: {
      type: Object,
      required: true,
    },
  },
  created() {
    if (this.staffContact != null) {
      this.form = JSON.parse(JSON.stringify(this.staffContact));
    }
  },
  watch: {
    staffContact: {
      handler: function (newVal, oldVal) {
        this.reloadForm();
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    ...mapGetters({
      staffContact: "profile/staff_contact",
      mode: "MODE"

    }),
  },
  methods: {
    ...mapActions({
      save: "profile/addStaffContact",
    }),
    storeContact() {
      this.form.staff_id = this.staff.id;
      if (this.form.staff_id) {
        this.save(this.form)
          .then((res) => {        
            if(res.status==201)
            {                
                this.parent().fetchStaffContact()
                this.close();
            }
          })
          .catch((err) => {            
          });
      }
    },
    reloadForm() {
      if (this.staffContact != null) {
        this.form = JSON.parse(JSON.stringify(this.staffContact));
      }
    },
    close() {      
      this.parent().closeDialog();
    },
    parent() {
      return this.$parent.$parent;
    },
    closed() {
      if (_.isFunction(this.afterLeave)) {
        this.afterLeave();
      }
    },
    opened() {       
      if (_.isFunction(this.afterOpen)) {
        this.afterOpen();
      }
    },
  },
};
</script>
<style>
.dialog-footer{
  background-color: transparent;
}
  </style>
