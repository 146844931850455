<template>
  <div v-can="'profile.workplace.index'">
    <!-- Places of work -->
    <work-places></work-places>
    <!--end  Places of work -->
  </div>
</template>
<script>
import WorkPlaces from "./components/workPlaces";
export default {
  components: {
    WorkPlaces
  },
  data() {
    return {};
  }
};
</script>
