<template>
  <div v-can="'profile.inventory.index'">
    <div
      class="card-table mb-5"
      :class="mode ? 'cardtable__day' : 'cardtable__night'"
    >
      <div class="workers-info-title-za">
        <div class="title">{{ $t("message.inventories") }}</div>
        <i
          class="el-icon-plus"
          @click="childrenCreate = true"
          v-can="'profile.inventory.create'"
        ></i>
      </div>
      <div
        class="workers-info madal-info-workers p-5 pt-2 pb-2"
        v-loading="loadChildren"
      >
        <table class="w-100" :class="mode ? 'table__myday' : 'table__mynight'">
          <tr>
            <td>
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
                >{{ $t("message.nameGiven") }}</strong
              >
            </td>
            <td>
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.quantity") }}
              </strong>
            </td>
            <td colspan="2">
              <strong
                class="mr-1 font-bold"
                :class="mode ? 'text__day2' : 'text__night2'"
              >
                {{ $t("message.status") }}
              </strong>
            </td>
          </tr>
          <tr v-for="(child, index) in staffChildren" :key="'child-' + index">
            <td>
              <p
                :class="mode ? 'text__day2' : 'text__night2'"
                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
              >
                <span> {{ child.inventory ? child.inventory.name : "" }} </span>
              </p>
            </td>

            <td>
              <p
                :class="mode ? 'text__day2' : 'text__night2'"
                class="
                                    text-left
                                    font-medium
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
              >
                <span>{{ child.counter }} </span>
              </p>
            </td>

            <td>
              <p
                :class="mode ? 'text__day2' : 'text__night2'"
                class="
                                    text-left
                                    font-large
                                    pb-1
                                    pt-0
                                    mb-3
                                    mt-3
                                "
              >
                <span
                  v-if="child.status == 'user_accepted'"
                  style="color:#17c700; font-size:18px !important"
                  ><i class="fa-regular fa-circle-check"></i
                ></span>
                <span
                  v-else-if="child.status == 'deny'"
                  style="color:#ff0000d9; font-size:18px !important"
                  ><i class="fa-regular fa-circle-xmark"></i
                ></span>
                <span
                  v-else
                  style="color:rgb(253, 131, 32); font-size:18px !important"
                  ><i class="fa-solid fa-circle-exclamation"></i
                ></span>
              </p>
            </td>
            <td class="text-right">
              <el-row>
                <el-button
                  v-if="child.status != 'user_accepted'"
                  type="success"
                  icon="el-icon-refresh"
                  circle
                  size="small"
                  @click="sendStaffInventory(child)"
                  v-can="'profile.inventory.edit'"
                ></el-button>

                <el-button
                  type="primary"
                  icon="el-icon-edit"
                  circle
                  size="small"
                  @click="editStaffInventory(child)"
                  v-can="'profile.inventory.edit'"
                ></el-button>

                <el-button
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  size="small"
                  @click="deleteInventory(child)"
                  v-can="'profile.inventory.destroy'"
                ></el-button>
              </el-row>
            </td>
          </tr>
        </table>

        <div class="d-flex mt-1" style="justify-content:end">
          <el-button
            icon="el-icon-refresh"
            type="warning"
            size="small"
            @click="sendAllAccepting()"
          >
            {{ $t("message.send_acceptor") }}
          </el-button>
        </div>
      </div>
    </div>

    <!-- start Modal  -->
    <el-dialog
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      :title="$t('message.add_dinventory')"
      :visible.sync="childrenCreate"
      width="60%"
      center
    >
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.inventory") }}
              </span>
              <el-select
                v-model="form.inventory_id"
                :class="mode ? 'input__day' : 'input__night'"
                :placeholder="$t('message.inventories')"
                filterable
                clearable
                size="medium"
                class="d-block"
              >
                <el-option
                  v-for="(inventory, index) in inventories"
                  :key="'inventories-' + index"
                  :label="inventory.name"
                  :value="inventory.id"
                ></el-option>
              </el-select>
              <!-- <select-inventory
                            :id="form.inventory_id"
                            v-model="form.inventory_id"
                            :size="'medium'"
                          ></select-inventory> -->

              <!-- <el-input
                            :class="mode ? 'input__day' : 'input__night'"
                                :placeholder="$t('message.education') "
                                v-model="form.education_level"
                                size="medium"
                            ></el-input> -->
            </div>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.quantity") }}
              </span>
              <crm-input
                :size="'medium'"
                :type="'number'"
                :inputValue="form.counter"
                v-model="form.counter"
                :placeholder="$t('message.quantity')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="submit()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="close()">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      :visible.sync="childrenUpdate"
      width="60%"
      center
      @open="open('childrenUpdate')"
    >
      <el-form ref="form" :model="form">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.education") }}
              </span>
              <el-select
                v-model="form.inventory_id"
                :class="mode ? 'input__day' : 'input__night'"
                :placeholder="$t('message.inventories')"
                filterable
                clearable
                size="medium"
                class="d-block"
              >
                <el-option
                  v-for="(inventory, index) in inventories"
                  :key="'inventories-' + index"
                  :label="inventory.name"
                  :value="inventory.id"
                ></el-option>
              </el-select>
              <!-- <select-inventory
                          :id="form.inventory_id"
                          v-model="form.inventory_id"
                          :size="'medium'"
                        ></select-inventory> -->

              <!-- <el-input
                          :class="mode ? 'input__day' : 'input__night'"
                              :placeholder="$t('message.education') "
                              v-model="form.education_level"
                              size="medium"
                          ></el-input> -->
            </div>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.quantity") }}
              </span>
              <crm-input
                :size="'medium'"
                :type="'number'"
                :inputValue="form.counter"
                v-model="form.counter"
                :placeholder="$t('message.quantity')"
              ></crm-input>
            </div>
          </el-col>
          <!-- end col -->
          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="update()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="close()">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { i18n } from "@/utils/i18n";
import { mapGetters, mapActions, mapState } from "vuex";
import selectNation from "@/components/filters/inventory/select-nation";
import selectGender from "@/components/filters/inventory/select-gender";
export default {
  components: {
    selectNation,
    selectGender
  },
  props: {
    staff: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      form: {},
      loadChildren: false,
      childrenCreate: false,
      childrenUpdate: false
    };
  },

  created() {
    this.fetchStaffInventory();
  },
  computed: {
    ...mapGetters({
      staffChildren: "profile/staff_inventory",
      inventories: "profile/inventories",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      getStaffInventory: "profile/getStaffInventory",
      getInventory: "profile/getInventory",
      sendAllAcceptor: "profile/sendAllAcceptor",
      sendOneAcceptor: "profile/sendOneAcceptor",
      addStaffInventory: "profile/addStaffInventory",
      deleteStaffInventory: "profile/deleteStaffInventory",
      updateStaffInventory: "profile/updateStaffInventory"
    }),
    submit() {
      this.form.staff_id = this.$route.params.id;
      if (this.form.staff_id) {
        this.addStaffInventory(this.form)
          .then(res => {
            this.$alert(res);
            if (res.status == 201) {
              this.clearForm();
              this.fetchStaffInventory();
              this.close();
            }
          })
          .catch(err => {
            this.$alert(err);
          });
      }
    },

    fetchStaffInventory() {
      if (!this.loadChildren && this.$route.params.id) {
        this.loadChildren = true;
        this.getStaffInventory(this.$route.params.id)
          .then(res => {
            this.loadChildren = false;
          })
          .catch(err => {
            this.loadChildren = false;
          });
        this.getInventory(this.$route.params.id)
          .then(res => {
            this.loadChildren = false;
          })
          .catch(err => {
            this.loadChildren = false;
          });
      }
    },
    close() {
      this.clearForm();
      this.childrenCreate = false;
      this.childrenUpdate = false;
    },
    clearForm() {
      for (var key in this.form) {
        this.form[key] = null;
      }
    },
    editStaffInventory(child) {
      this.form = JSON.parse(JSON.stringify(child));
      this.childrenUpdate = true;
    },

    open(refs) {
      this.$refs[refs].opened();
    },
    opened(refs) {
      this.$refs[refs].opened();
    },
    sendStaffInventory(param) {
      this.loadChildren = true;
      this.sendOneAcceptor(param.id)
        .then(res => {
          this.$alert(res);
          this.loadChildren = false;
          if (res.status == 201) {
            this.fetchStaffInventory();
          }
        })
        .catch(err => {
          this.loadChildren = false;
          console.log(err);
          this.$alert(err);
        });
    },
    sendAllAccepting() {
      this.loadChildren = true;
      console.log("sendAllAcceptor");
      this.sendAllAcceptor(this.$route.params.id)
        .then(res => {
          this.$alert(res);
          this.loadChildren = false;
          this.fetchStaffInventory();
          // if (res.status == 201) {

          // }
        })
        .catch(err => {
          this.loadChildren = false;
          console.log(err);
          this.$alert(err);
        });
    },
    update() {
      this.form.staff_id = this.$route.params.id;
      this.form.child_id = this.form.id;
      console.log(this.form);
      if (this.form.staff_id && this.form.child_id) {
        this.updateStaffInventory(this.form)
          .then(res => {
            this.$alert(res);
            if (res.status == 201) {
              this.clearForm();
              this.fetchStaffInventory();
              this.close();
            }
          })
          .catch(err => {
            this.$alert(err);
            this.loadChildren = false;
          });
      }
    },
    deleteInventory(child) {
      this.$confirm("Вы действительно хотите это сделать?", "Предупреждение", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмен",
        type: "warning"
      })
        .then(() => {
          if (!this.loadChildren && this.$route.params.id) {
            this.loadChildren = true;
            let form = {
              staff_id: this.$route.params.id,
              child_id: child.id
            };
            this.deleteStaffInventory(form)
              .then(res => {
                this.loadChildren = false;
                this.$alert(res);
                if (res.status == 200) {
                  console.log("stfjkhdihjhasidfh;sdh");
                  this.fetchStaffInventory();
                }
              })
              .catch(err => {
                this.loadChildren = false;
              });
          }
        })
        .catch(() => {
          this.$message({
            type: "warning",
            message: "Операция отменена"
          });
        });
    }
  }
};
</script>
