<template>
    <div>
        <div id="chart">
            <apexchart
                type="bar"
                height="350"
                :key="mode"
                :options="chartOptions"
                :series="series"
            ></apexchart>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
export default {
    data() {
        return {
            series: [
                {
                    name: "Net Profit",
                    data: [
                        1239, 4455, 3570, 5560, 6561, 6580, 6783, 4560, 6786,
                        4786, 9786, 1239, 4455, 5570, 5560, 3561, 6580, 6783,
                        4560, 3786, 4786, 1239, 3455, 5570, 5560, 6561, 6580,
                        6783, 4560, 6786,
                    ],
                },
            ],
            chartOptions: {
                chart: {
                    type: "bar",
                    height: 350,
                    foreColor: ''
                },
                plotOptions: {
                    bar: {
                        borderRadius: 5,
                        horizontal: false,
                        columnWidth: "40%",
                        endingShape: "rounded",
                    },
                },

                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ["transparent"],
                },
                xaxis: {
                    categories: [
                        "1",
                        "2",
                        "3",
                        "4",
                        "5",
                        "6",
                        "7",
                        "8",
                        "9",
                        "10",
                        "11",
                        "12",
                        "13",
                        "14",
                        "15",
                        "16",
                        "17",
                        "18",
                        "19",
                        "20",
                        "21",
                        "22",
                        "23",
                        "24",
                        "25",
                        "26",
                        "27",
                        "28",
                        "29",
                        "30",
                    ],
                },
                yaxis: {
                    title: {
                        text: "$ (thousands)",
                    },
                },
                fill: {
                    opacity: 1,
                },
                colors: [
                    function ({ value, seriesIndex, w }) {
                        if (value < 4000) {
                            return "#f56c6cbd";
                        } else {
                            return "#67C23A";
                        }
                    },
                ],
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "$ " + val + " thousands";
                        },
                    },
                },

                annotations: {
                    position: "back",
                    yaxis: [
                        {
                            label: {
                                text: " ",
                            },
                            y: 4000,
                            y2: 10000,
                            fillColor: "#67C23A",
                        },
                        {
                            label: {
                                text: " ",
                            },
                            y: 0,
                            y2: 4000,
                            fillColor: "#f56c6cbd",
                        },
                    ],
                },
            },
        };
    },
    created() {
      this.changeOtion()
    },
    watch: {
      mode () {
        this.changeOtion()
      }
    },
    computed: {
      ...mapGetters({
        mode: "MODE"
      })
    },
    methods: {
      changeOtion () {
        if (this.mode) {
          this.chartOptions.chart.foreColor = '#373d3f'
        } else {
          this.chartOptions.chart.foreColor = '#fff'
        }
      }
    }
};
</script>
