<template>
  <div class="card-table mb-5" :class="mode ? 'cardtable__day' : 'cardtable__night'">
      <div class="workers-info-title-za">
          <div class="title">REFA</div>
          <!-- <el-select v-model="value" placeholder=" Процесс 1">
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
          </el-select> -->
      </div>
      <el-row :gutter="20" class="margin__none">
          <el-col :xs="24" :sm="8">
              <div class="refa__item">
                  <el-card class="box-card bg-light-primary" :class="mode ? '' : 'text-primary__night'">
                      <i
                          class="my-icon el-icon-pie-chart text-primary"
                      ></i>
                      <div class="homepayment__flex">
                        <div class="text-center">
                            <span class="text-primary"> Процесс </span>
                        </div>
                        <div class="text-center mt-3">
                            <b class="text-primary">Name Процесс</b>
                        </div>
                      </div>
                  </el-card>
              </div>
          </el-col>
          <el-col :xs="24" :sm="8">
              <div class="refa__item">
                  <el-card class="box-card bg-light-success" :class="mode ? '' : 'text-success__night'">
                      <i
                          class="
                              my-icon
                              el-icon-collection-tag
                              text-success
                          "
                      ></i>
                      <div class="homepayment__flex">
                        <div class="text-center">
                            <span class="text-success"> Норма </span>
                        </div>
                        <div class="text-center mt-3">
                            <b class="text-success">110</b>
                        </div>
                      </div>
                  </el-card>
              </div>
          </el-col>
          <el-col :xs="24" :sm="8">
              <div class="refa__item">
                  <el-card class="box-card bg-light-danger" :class="mode ? '' : 'text-danger__night'">
                      <i
                          class="my-icon el-icon-data-line text-danger"
                      ></i>
                      <div class="homepayment__flex">
                        <div class="text-center">
                            <span class="text-danger"> Факт </span>
                        </div>
                        <div class="text-center mt-3">
                            <b class="text-danger"> 104 </b>
                        </div>
                      </div>
                  </el-card>
              </div>
          </el-col>
      </el-row>

      <homeRefaChart class="refa__chart"></homeRefaChart>
  </div>
</template>

<script>
import HomeRefaChart from "./home-refa-chart.vue";
import {mapGetters} from "vuex"
export default {
    components: {
        HomeRefaChart,
    },
    computed: {
      ...mapGetters({
        mode: "MODE"
      }),
    }
}
</script>
